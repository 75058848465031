(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("jquery"));
	else if(typeof define === 'function' && define.amd)
		define("DBP", ["jquery"], factory);
	else if(typeof exports === 'object')
		exports["DBP"] = factory(require("jquery"));
	else
		root["DBP"] = factory(root["$"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE__0__) {
return 